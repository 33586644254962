@import '../../kit/styles/themes.scss';

.table-view{
  .locked-codemirror-wrapper{
    .react-codemirror2,.CodeMirror{
      background: $gs9;
    }
  }

  textarea{
    // font-weight: 400;
    // padding: 2.5rem;
  }
}