@import '../../kit/styles/themes.scss';

.header{

  background: $gs10;
  padding: 1rem;
  box-shadow: $boxShadowLarge;

  .brand{
    font-size: 2rem;
    font-weight: 900;
  }

}