$brandFontFamily: 'Press Start 2P', monospace;
$monospaceFontFamily: 'Roboto Mono', monospace;
$serifFontFamily: 'PT Serif', serif;;
$readableFontFamily: "Anuphan",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$primary: #53b882;
$secondary: #8164c5;

$primaryDarker: darken($primary, 15%);

$success: #53b882;
$danger: #D24128;
$warning: #faaa35;
$info: #71CDFF;
$yellow: #e8c50a;
$pink: #ea596e;
$purple: #7851a9;
$green: #53b882;
$blue: #027CFE;
$orange: #faaa35;
$dark-soft: #8899A6;
$dark: #424E57;

$white: #fff;
$lightest-grey: #F6F7F9;
$light-grey: #E1E4EA;
$medium-grey: #C0C7D3;
$grey: #788690;
$dark-grey: #1B262E;
$darkest-grey: #141B1F;
$black: #0A0E0F;


$g: #000; 
$gs10: lighten($g, 100%);
$gs95: lighten($g, 95%);
$gs9: lighten($g, 90%);
$gs8: lighten($g, 80%);
$gs7: lighten($g, 70%);
$gs6: lighten($g, 60%);
$gs5: lighten($g, 50%);
$gs4: lighten($g, 40%);
$gs3: lighten($g, 30%);
$gs2: lighten($g, 20%);
$gs1: lighten($g, 10%);
$gs0: $g;

$link-color: $primary;
$link-hover: darken($primary, 15%);
$link-active: darken($primary, 30%);


$commonRadius: 0rem;
$fieldBorderRadius: 0px;
$fieldBorderThickness: 1px;

$boxShadowSmall: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 3px 3px -1px rgba(8, 11, 14, 0.1);
$boxShadowMedium: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1);
$boxShadowLarge: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 12px 12px -1px rgba(8, 11, 14, 0.08);

$boxShadowSmallHover: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 12px 12px -1px rgba(8, 11, 14, 0.1);

$boxShadowBorder: 1px solid #F7F8F9;

:export {
  success: $success;
  danger: $danger;
}
