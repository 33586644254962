@import '../../kit/styles/themes.scss';

.filez{
  .filez-file{
    padding: 1rem;
    border: 1px solid $gs8;
    margin-bottom: 1rem;
    margin-top: 1rem;

  }
}