@import '../../styles/themes.scss';


.custom-table{
  // padding: 2rem;
  .custom-table-main{
    width: 100%;

    thead{
      tr{

        th{
          cursor: pointer;
          text-align: left;
          border-bottom: 1px solid $gs0;
          &:hover{
            background: $gs9;
          }
        }
      }
    }

    tbody{
      tr{
        &.tr-danger{
          background: lighten($danger, 40%);
        }
        &.tr-linked{

          &:hover{
            background: $gs9;
          }
          td{
            cursor: pointer;
          } 
        }
        td{
          font-size: 1.3rem;
          color: $gs0;
          border-bottom: 1px solid $gs8;
          padding: 1rem;
          margin: 0;
        }
      }
    }
  }

  &.custom-table-align-top{
    .custom-table-main{
      tbody{
        tr{
          td{
            vertical-align: top;
          }
        }
      }
    }
  }
}
