@import '../../kit/styles/themes.scss';


.dashboard-chart{
  height: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  &.dashboard-chart-editing{
    // background: $primary;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    // outline: 2px solid $primary;
  }


  .drag-handle{
    cursor: move;
  }

  .dashboard-chart-key-row{
    padding-top: 1rem;
  }


  .dashboard-chart-svg-wrapper{
    // border: 1px solid #000;
    position: relative;
    flex-grow: 1;

    .dashboard-chart-svg-child{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      svg{
        height: 100%;
        width: 100%;


        .bg-super-light{
          fill: $gs95;
        }

        .dashboard-chart-label{
          font-size: 1.2rem;
        }

        .tick{
          font-size: 9px;
          line{
            stroke: rgba(0,0,0,.2);
          }
        }
      }

    }
  }  
}


@keyframes color {
  0% {
    background-color: $gs10;
    outline: 3px solid transparent;
  }
  50% {
    background-color: lighten($primary,45%);
    outline: 3px solid $primary;
  }
  100% {
    background-color: $gs10;
    outline: 3px solid transparent;
  }
}