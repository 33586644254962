@import '../../styles/themes.scss';



.custom-chat{
  width: 100%;
  height: 100%;
  // min-width: min(100vw, 800px);
  // min-height: calc(100vh - 108px - 60px);
  // height: calc(100vh - 108px - 60px);
  // max-height: calc(100vh - 108px - 60px);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  

  .custom-chat-column{
    position: relative;
    // flex: .5;
    height: 100%;
  }

  .custom-chat-variables{
    padding: 1rem;
    // border-right: 1px solid $gs9;
    background: $gs9;
  }

  .custom-chat-inner{

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // padding: 1rem;

    .custom-chat-form{
      background: $gs10;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border: none;
      border-top: 1px solid $gs8;
      height: 5.5rem;

      textarea{
        flex-grow: 1;
        font-family: $readableFontFamily;
        font-size: 1.4rem;
        padding-top: .6rem;
        padding-left: 1rem;
        // padding-bottom: 1rem;
        box-sizing: border-box;
        border: 1px solid transparent;
        resize: none;
        margin-bottom: .5rem;
        height: 5.5rem;
        max-height: 5.5rem;
        background: transparent;
        &:focus,&:active{
          background: transparent;
          outline: none;
          // border-top: 1px solid $blue;
        }
      }

      button{
        border: none;
        
        background: $blue;
        color: $gs10;
        font-size: 1.4rem;
        width: 3.5rem;
        height: 5.5rem;
        min-width: 3.5rem;
        min-height: 5.5rem;
        cursor: pointer;

        &.button-disabled{
          opacity: .5;
          pointer-events: none;
        }

        &:hover{
          color: $primary;
        }
        &:active{
          color: $primary;
        }
      }
    }


    .custom-chat-convo{
      flex-grow: 1;
      
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px;

      .custom-chat-row-wrapper{
        margin-bottom: 2rem;
        &:first-child{
          // margin-top: 40px;
        }
      }
     
      .custom-chat-row{
        display: flex;
        align-items: center;
        // margin-bottom: 5px;;
        // padding-left: 10px;
        // padding-right: 10px;

        &.custom-chat-row-topic-change{
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 3rem;
          margin-bottom: 2rem;

          .custom-chat-row-topic-change-hr{
            border-bottom: 1px solid $gs9;
            flex: .5;
          }
          .custom-chat-row-topic-change-details{
            max-width: 50%;
            text-align: center;
            color: $gs4;
            margin-left: 1rem;
            margin-right: 1rem;
          }
        }

        &.custom-chat-row-bot{
          justify-content: flex-start;
          word-break: break-word;
          white-space: normal;
          // padding-right: 20%;
          .custom-chat-message-time{
            text-align: left;
          }
        }

        &.custom-chat-row-user{
          justify-content: flex-end;
          word-break: break-word;
          white-space: normal;
          // padding-left: 20%;
          .custom-chat-message-time{
            text-align: right;
          }
        }

        .custom-chat-message-delete-button{
          position: relative;
          margin: 10px;
          top: 6px;
          cursor: pointer;
          color: $gs7;
          &:hover{
            color: $danger;
          }
        }
          .custom-chat-message-time{
            font-size: 10px;
          }



        .custom-chat-message{
          position: relative;
          // z-index: 200;
          &.handle{
            cursor: grab;
            &:active{
              cursor: grabbing;
            }
          }

          .custom-chat-message-body{
            padding: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 14px;
            margin: 1px;
            font-family: $readableFontFamily;
            word-break: break-word;
          }  

          &.custom-chat-message-bot{
            .custom-chat-message-body{
              background: $gs95;
              color: $gs0;
              border-radius: 19px 19px 19px 0px;
              // border: 1px solid $;
              word-break: break-word !important;
              &.custom-chat-message-body-double-send{
                border-radius: 0px 19px 19px 0px;
              }
            }
          }
          &.custom-chat-message-user{
            .custom-chat-message-body{
              background: $blue;
              color: $gs10;
              border-radius: 19px 19px 0px 19px;
              word-break: break-word !important;
              &.custom-chat-message-body-double-send{
                border-radius: 19px 0px 0px 19px;
              }
            }
          }
        }
      }
    }

  }
}
